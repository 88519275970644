// Here you can add other styles

.black-placeholder {
    ::placeholder {
        color: $input-color;
        opacity: 1;
    }
}

.fc-toolbar-title, .fc-col-header-cell-cushion  {
    text-transform: capitalize;
}

.fc-event-time, .fc-event-title {
    padding: 0 1px;
    white-space: normal;
    }

.workflow {
    background-color: white;
    .wf-first-row {
        td {
            position: relative
        }
    }
    .wf-first-row {
        td {
            position: sticky;
            top: 112px;
            z-index: 1;
            background-color: white;
        }
    }
    .wf-first-col {
        td:first-child {
            position: sticky;
            left: 255px;
            z-index: 2;
            background: white;
        }
    }
    .wf-input {
        font-size: '10px';
        border: '1px solid lightgray';
        background: 'transparent';
    }
}

.widget-scale {
    .fs-4 {
        font-size: 1.4cqw !important;
    }
}

a.collapse-switch {
    text-decoration: none; 
    color: $body-color;
}